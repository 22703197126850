.item-container{
    width: 100%;
    height: 77vh;
    display: flex;
    padding-top: 100px;
    align-items: center;
    justify-content:space-between;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.item-container-reverse{
    width: 100%;
    height: 77vh;
    display: flex;
    flex-direction: row-reverse;
    padding-top: 100px;
    align-items: center;
    justify-content: space-between;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.item-content{
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 1rem;
}

.item-content .text-lg{
    margin: 2rem auto 0 auto;
    padding: 0;
    font-size: 2.2rem;
    line-height: 2.3rem;
    font-weight: 800;
    text-transform: uppercase;
    color:  #00217A ;
}

.item-content .text-sm{
    display: block;
    text-align: start;
    font-size: 1.5rem;
    line-height: 1.4rem;
    font-weight: 600;
    padding: 0.3rem 1rem;
    
}

.item-container .item-content .text-lg{
    margin: 1.5rem 0 0 3.5rem;
}

.item-container-reverse .item-content .text-lg{
    margin: 1.5rem 3.5rem 0 0;
}

.item-container .item-content .text-sm{
    margin: 1rem 0 0.5rem 3.5rem;
}

.item-container-reverse .item-content .text-sm{
    margin: 1rem 3.5rem 0.5rem 0;
}

.item-container .item-content .text{
    margin: 0 auto 0 3.5rem;
}

.item-container-reverse .item-content .text{
    margin: 0 auto 0 0;
}

.item-content .text{
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.3rem 1rem;
    font-weight: 700;
    color: #00217A;
    

}

.item-content .text a{
    text-decoration: none;
    color: #00217A;
}

.item-content i{
    padding-right: 0.7rem;
    font-weight: 500;
    font-size: 1.4rem;

}

.item-content .btn{
    border: 0;
    border-radius: 13px;
    margin: auto auto 2rem auto;
    padding: .3rem 1.5rem;
    font-size: 1.4rem;
    font-weight: 600;
    outline: 0;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    transition: all .2s ease-in-out;
}

.item-content .btn:hover{
    filter: brightness(1.1);
    padding: .3rem 1.2rem;

}

.item-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 100%;
    filter: brightness(0.9);
}

.item-img img{
    width: 100%;
    min-height: 50%;
    max-height: 100%;
    margin-top: auto;
    object-fit:contain;
}

.carousel .slick-dots {bottom: 0.8rem;}

.carousel .slick-dots li.slick-active button:before {
    color:#FFF01A !important;
}

.carousel .slick-dots li button:before {
    color:#0A37B2 !important;
    font-size: 12px;
}

.slick-prev:before, .slick-next:before{
    color:#5C86FA !important;
    font-size: 30px;
}

@media screen and (max-width: 950px){

    .item-content .text-lg{
        font-size: 1.8rem;
        margin-top: 1rem  !important;
    }
    
    .item-content .text-sm{
        font-size: 1.3rem;
    }
    
    .item-content .text{
        margin: 0.5rem auto 0 3.5rem;
        font-size: .9rem;
        line-height: 1.2rem;
        font-weight: 600;
    }

    .item-content .btn{
        margin: auto auto 2rem auto;
        padding: .3rem 1.5rem;
        font-size: 1.2rem;
        font-weight: 500;
    }
    
}

@media screen and (max-width: 768px){

    .item-container{
        height: 100vh;
        flex-direction: column;
    }

    .item-container-reverse{
        height: 100vh;
        flex-direction: column;
    }

    .item-content{
        width: 100%;
        height: 50%;
        justify-content:flex-end;
    }

    .item-content .text-lg{
        font-size: 2rem;
        letter-spacing: 1px;
        margin: 1rem 0px 1rem 0px !important;
        -webkit-text-stroke-width: .9px;
        -webkit-text-stroke-color: rgb(193, 190, 190);
    }
    .item-content .text-sm{
        font-size: 1.5rem;
        font-weight: 700;
        margin: 1rem 0 2rem 0 !important;
        -webkit-text-stroke-width: .7px;
        -webkit-text-stroke-color: rgb(0, 0, 48);
    }

    .item-content .text{
        display: none;
    }    

    .item-content .btn{
        margin: 0;
        padding: .3rem 1.5rem;
        font-size: 1.4rem;
        font-weight: 500;
    
    }

    .item-img{
        width: 100%;
        height: 50%;
        margin: 0px;
    }

    .item-img img{
        padding-top: 1rem;
    }

    
}