.createnews-container, .editpost-container{
    padding: 2rem 0;
}

.createnews-container h2, .editpost-container .edit-title{
    color: #0C0F16;
    text-transform: uppercase;
    text-align: center;
}

.createnews-container form, .editpost-container form{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
}

.createnews-container input, .editpost-container input{
    color: #303749;
    width: 100%;
    height: 2.2rem !important;
    padding: 0 1rem;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    border: 2px solid rgba(0, 0, 0, 0.35);
    font-size: .9rem;
}

.createnews-container input[type=file], .editpost-container input[type=file]{
    display: none;
}

.createnews-container label, .editpost-container label{
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createnews-container label span, .editpost-container label span{
    text-decoration: none;
    width: 100%;
    height: 2.2rem !important;
    padding: 0.5rem 1rem;
    border: 2px solid rgba(0, 0, 0, 0.35);
    font-size: .9rem;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
}

.createnews-container label .file-title, .editpost-container label .file-title{
    width: 60px;
    font-weight: 500;
    background: #eaecec;
    color: #303749;
    border-radius: 0.3rem 0 0 0.3rem;
}

.createnews-container label .file-name, .editpost-container label .file-name{
    background: #ffffff;
    color: #303749;
    border-radius: 0 0.3rem 0.3rem 0;
}

.createnews-container label .file-title:hover, .editpost-container label .file-title:hover{
    color: #0A37B2;
  }

.createnews-container textarea, .editpost-container textarea{
    color: #303749;
    resize: vertical;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    border: 2px solid rgba(0, 0, 0, 0.35);
    font-size: .9rem;
}

.createnews-container .ql-container {
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    border: 2px solid rgba(0, 0, 0, 0.35);
    color: #303749;
    background: #fefcfc;
    font-size: .9rem;
    overflow-y: auto;
  }

.createnews-container .ql-snow.ql-toolbar {
    display: block;
    text-align: center;
    background: #eaecec;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    border: 2px solid rgba(0, 0, 0, 0.35);
  }

.createnews-container button, .editpost-container button{
    background-color: #E80A24;
    color: #fff;
    padding: 0.4rem 1.2rem;
    margin: 1rem 0;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: 2px solid #e80a2486;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.createnews-container button:hover, .editpost-container button:hover{
    background-color: #CB061D;
    border-color: #CB061D;
}

/* -------------------------------  Newsformat Styles ------------------------------------ */

.newsformat-container{
    margin: 3rem;
    height: 40vh;
    box-shadow: 1px 35px 50px -58px rgba(0,0,0,0.50);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.first{
    height: 70vh !important;
}

.inverse{
    display: flex;
    flex-direction: row-reverse;
}

.first .item-img{
    width: 55% !important;
    height: 100% !important;
    padding: 1rem;
    align-self:flex-start !important;
}

.newsformat-container .item-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 100%;
    padding: 1rem;

}

.newsformat-container .item-img img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    padding: 0;
    border-radius: 0.5rem;
}

.first .item-text{
    width: 45% !important;
    height: 100% !important;
    padding: 2rem 1rem !important;
}

.first .tag{
    color: #E80A24;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
}

.newsformat-container .item-text{
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: auto;
    padding: 0.5rem 2rem;
    text-align: start;
}

.first .title{
    position: static !important;
}

.newsformat-container .title{
    width: 100%;
    font-size: 1.7rem;
    font-weight: 700;
    text-decoration: none;
    line-height: 2rem;
    color: #2a2a2a;
    transition: all 0.2s ease-in-out;
}

.newsformat-container .title:hover{
    color: #0A37B2;
}

.newsformat-container .resumen{
    width: 100%;
    margin: 0.5rem 0;
    text-align: justify;
    font-size: 1rem;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.first .resumen{
    -webkit-line-clamp: 5 !important;
}

.newsformat-container .bottom-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}

.newsformat-container .bottom-content .date{
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 0.8rem;
}

.newsformat-container .bottom-content .date > i{
    padding-right: 0.5rem;
    text-align: end;
    font-size: 1rem;
    font-weight: 500;
    color: #0A37B2;
}

.newsformat-container .bottom-content .news-btn{
    text-align: end;
    margin-top: auto;
    margin-left: auto;
    margin-bottom: .5rem;
    text-decoration: none;
    background-color: #0A37B2;
    color: #fff;
    padding: 0.2rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 2px solid #0A37B2;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.newsformat-container .bottom-content .news-btn:hover{
    background-color: #01237E;
    border-color: #01237E;
}

/* -------------------------------  SingleNews Styles ------------------------------------ */

.singlenews-container{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding: 1rem 2rem;
}

.singlenews-container h1{
    margin-top: 1rem;
    text-align: center;
    font-size: 2.3rem;
    line-height: 2.5rem;
}

.singlenews-container .middle-section{
    padding: 2rem 5rem 0.5rem 5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.singlenews-container .middle-section span{
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
}

.singlenews-container .middle-section span > i{
    padding-right: 0.5rem;
    text-align: end;
    font-size: 1rem;
    font-weight: 500;
    color: #0A37B2;
}

.singlenews-container .middle-section .btn-panel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.singlenews-container .middle-section .btn-panel .btn{
    margin: 0 0.5rem;
    text-decoration: none;
    background-color: #0A37B2;
    color: #fff;
    padding: 0.3rem 1.2rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 2px solid #0A37B2;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.singlenews-container .middle-section .btn-panel .btn:hover{
    background-color: #01237E;
    border-color: #01237E;
}

.singlenews-container .middle-section .btn-panel .del{
    background-color: #E80A24 !important;
    border-color: #E80A24 !important;
}

.singlenews-container .middle-section .btn-panel .del:hover{
    background-color: #CB061D !important;
    border-color: #CB061D !important;
}

.singlenews-container .middle-section .btn-panel .btn > i{
    padding-right: 0.5rem;
    font-size: 1rem;
}

.singlenews-container .middle-section .social-media i{
    width: 40px;
    height: 40px;
    background-color: #0A37B2;
    border-radius: 13px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.2rem;
    margin-left: 1rem;
    transition: all 0.2s ease-in-out;
}

.singlenews-container .middle-section .social-media i:hover{
    color: #FFF01A;
}

.singlenews-container .item-image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
    padding: 0.5rem 4rem;

}

.singlenews-container .item-image img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 0.5rem;
}

.singlenews-container .item-content{
    width: 100%;
    padding: 0.5rem 5rem;
}

.singlenews-container .item-content > *{
    width: 100%;
}

.singlenews-container blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
}

/* ---------------------------------------  Dashboard Styles  ------------------------------------------- */

.DashboardN-container{
    background-color: #00217A;
    position: relative;
    padding: 5rem 2rem 0 2rem;
    display: flex;
    flex-direction: row;
    align-items:stretch;
    justify-content: center;
    box-shadow: 1px 35px 50px -58px rgba(0,0,0,0.50);
  }
  .DashboardN-container .header{
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 3.5rem;
    padding-top: 1.5rem;
    
  }

  .DashboardN-container .header h2 {
    text-align: start;
    font-size: 1.6rem;
    color: #F7F7F7;
}
  
.DashboardN-container .header span {
    text-align: start;
    color: #C2C6D2;
    font-size: 0.9rem;
}
  .DashboardN-container .principal{
    width: 60%;
    height: 80vh;
    margin: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .DashboardN-container .list{
    width: 40%;
    height: 80vh;
    margin: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }

  .DashboardN-container .principal .item-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
    padding: 1rem;

}

.DashboardN-container .item-img img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    padding: 0;
    border-radius: 0.5rem;
}

.DashboardN-container .principal .item-text{
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: auto;
    padding: 0.5rem 1rem;
}

.DashboardN-container .principal .top-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    margin-bottom: auto;
    text-align: start;
    margin-bottom: 0.5rem;
}

.DashboardN-container .principal .title{
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    line-height: 1.7rem;
    color: #2a2a2a;
    transition: all 0.2s ease-in-out;
    color: #FFF01A;
}

.DashboardN-container .principal .title:hover{
    color: #FEF685;
}

.DashboardN-container .principal .date{
    min-width: 120px;
    text-align: right;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: auto;
    color: #F7F7F7;
}

.DashboardN-container .principal .date > i{
    padding-right: 0.5rem;
    text-align: end;
    font-size: 1rem;
    font-weight: 500;
    color: #9DA3B5;
}

.DashboardN-container .principal .resumen{
    width: 100%;
    text-align: justify;
    font-size: 0.9rem;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color:#F7F7F7;
}

.DashboardN-container .list .content{
    width: 100%;
    height: 27%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.DashboardN-container .list .item-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    padding: 0.1rem;

}

.DashboardN-container .list .item-text{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: auto;
    padding: 0 0.7rem;
}

.DashboardN-container .list .title{
    font-size: .9rem;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.3rem;
    color: #FFF01A;
    transition: all 0.2s ease-in-out;
}

.DashboardN-container .list .title:hover{
    color: #FEF685;
}

.DashboardN-container .list .date{
    min-width: 120px;
    text-align: left;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: auto;
    padding: 0.3rem;
    color: #F7F7F7;
}

.DashboardN-container .list .date > i{
    padding-right: 0.5rem;
    text-align: end;
    font-size: 1rem;
    font-weight: 500;
    color: #9DA3B5;
}

.DashboardN-container .news-btn{
    text-align: center;
    margin-top: 1rem;
    margin-right: auto;
    text-decoration: none;
    color: #EADB06;
    padding: 0.2rem 0;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.DashboardN-container .news-btn span{
    padding-left: 0.5rem;
    transition: all 0.2s ease-in-out;
}

.DashboardN-container .news-btn:hover span{
    padding-left: 1.5rem;
}

@media screen and (max-width: 1000px){

    .createnews-container form{
        width: 80%;
    }

    .newsformat-container{
        position: relative;
        margin: 3rem 2rem;
    }

    .first .item-img{
        position: absolute;
        width: 100% !important;
        opacity: 0.4;
        z-index: -1;
    
    }

    .first .item-text{
        width: 100% !important;
        padding: 2rem !important;
    
    }

    .first .title{
        padding: 0.5rem 0 !important;
        -webkit-line-clamp: 3 !important;
        color: #0C0F16 !important;
        backdrop-filter: blur(2px);
    }

    .first .tag{
        backdrop-filter: blur(2px);
    }

    .first .title:hover{
        color: #0A37B2 !important;
    }

    .first .resumen{
        color: #0C0F16 !important;
    }

    .newsformat-container .title{
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        padding: 0.5rem 1rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .newsformat-container .item-img{
        width: 45%;
        height: 70%;
        padding: 1rem;
        align-self: flex-end;
    }

    .newsformat-container .item-text{
        width: 55%;
        height: 70%;
        margin: 0;
        align-self: flex-end;
        justify-self: flex-end;
        padding: 0.5rem 1rem;
    }

    .newsformat-container .resumen{
        -webkit-line-clamp: 3;
    }

    .DashboardN-container{
        padding: 5rem 2rem 0 2rem;
        display: flex;
        flex-direction: column;
        align-items:center;
      }

      .DashboardN-container .principal{
        width: 100%;
        height: 70vh;
      }
      
      .DashboardN-container .list{
        width: 100%;
        height: 70vh;
        margin: 0.5rem;
      }

      .DashboardN-container .list .content{
        height: 25%;
    
    }
    
    .DashboardN-container .list .item-img{
        width: 30%;
    }
    
    .DashboardN-container .list .item-text{
        width: 70%;
    }
}

@media screen and (max-width: 700px){

    .newsformat-container{
        height: 50vh;
        margin: 2rem 1rem;
        flex-direction: column !important;
        align-items: center;
        justify-content: center ;
    }

    .newsformat-container .item-img{
        width: 100%;
        height: 60%;
        padding: 0.5rem;
        align-self: auto;
    }

    .newsformat-container .item-text{
        width: 100%;
        height: 40%;
        padding: 0 0.5rem 0.5rem 0.5rem ;
        align-self: auto;
    }

    .first .title{
        font-size: 1.3rem !important;
        font-weight: 600;
        line-height: 1.5rem;
        padding: 0;
        position: static;
        -webkit-line-clamp: 5 !important;
    }

    .newsformat-container .title{
        font-size: 1.2rem !important;
        font-weight: 600;
        line-height: 1.5rem;
        padding: 0;
        position: static;
        -webkit-line-clamp: 3;
    }

    .newsformat-container .resumen{
        display: none;
    }

    .first .resumen{
        display: block !important;
        -webkit-line-clamp: 7 !important;
    }

    .newsformat-container .bottom-content .date{
        text-align: center;
        font-size: 0.7rem;
        font-weight: 600;
        padding: 0.2rem;
    }
    
    .newsformat-container .bottom-content .news-btn{
        text-align: end;
        margin-top: auto;
        margin-left: auto;
        margin-bottom: 0;
        text-decoration: none;
        background-color: #0A37B2;
        color: #fff;
        padding: 0.2rem 0.5rem;
        white-space: nowrap;
        border-radius: 0.3rem;
        font-size: 0.7rem;
        font-weight: 500;
        border: 2px solid #0A37B2;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }

    .singlenews-container{
        padding: 1rem;
    }

    .singlenews-container .middle-section{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .singlenews-container h1{
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .singlenews-container .middle-section .social-media i{
        width: 35px;
        height: 35px;
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    
    .singlenews-container .item-image{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40vh;
        padding: 0.5rem 1rem;
    
    }

    .singlenews-container .item-content{
        padding: 0.5rem 1.5rem;
    }

    .DashboardN-container{
        padding: 5rem 1rem 0 1rem;
      }

      .DashboardN-container .header{
        padding-left: 2.5rem;
        
      }

      .DashboardN-container .header span{
        font-size: 0.9rem;
        
      }

      .DashboardN-container .principal{
        width: 100%;
        height: 60vh;
      }

      .DashboardN-container .principal .item-img{
        padding: 0;
      }
      .DashboardN-container .principal .item-text{
        padding: 0.5rem;
      }

      .DashboardN-container .principal .top-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        gap: 8px;
        text-align: start;
    }
    
    .DashboardN-container .principal .title{
        width: 100%;
        font-size: 1rem;
    }
    
    .DashboardN-container .principal .date{
        margin-left: auto;
    }

    
    .DashboardN-container .principal .resumen{
        display: none;
    }
      
      .DashboardN-container .list{
        padding-top: 0;
        height: auto;
        gap: 20px
      }

      .DashboardN-container .list .content{
        height: 25%;
        flex-direction: column;
    
    }
    
    .DashboardN-container .list .item-img{
        width: 100%;
        height: 130px;
    }
    
    .DashboardN-container .list .item-text{
        padding-top: 1rem;
        width: 100%;
        height: auto;
    }

    .DashboardN-container .list .item-text .title{
        font-weight: 500;
        font-size: 0.9rem;
    }

    .DashboardN-container .list .item-text .date{
        margin-left: auto;
        margin-top: 0.5rem;
    }
}