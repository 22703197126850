.Tabs-container{
    background-color: #00217A;
    height: 100%;
    width: 100%;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 13px 0 0 0;

}
.Tabs-container span{
    font-size: .8rem;
    text-align: start;
    color: #9DA3B5;
}

.Tabs-container h3{
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    padding-bottom: 1.5rem;
    margin: 0;
    text-align: start;
    color: #fff;
}

.tabs-box{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
}

.tabs-box button{
    color: #C2C6D2;
    width:100%;
    padding: .3rem 0;
    background:none;
    font-size: .9rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-bottom: 1px solid #fff;
}

.tabs-box button.active{
    color: #FFF01A;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #fff;
    border-bottom: none;
    background-color: #00217A;

}

.tabs-body{
    display: none;
    height: 100%;
    animation: move .5s ease;
}

@keyframes move {
    from{ transform: translateX(50px); opacity: 0;}
    to{ transform: translateX(0px); opacity: 1;}
}

.tabs-body.active{
    display: block;
}


@media screen and (max-width: 950px){

    .Tabs-container{
        border-radius: 0;
    
    }

    .tabs-box button{
        font-size: .8rem;
        font-weight: 500;

    }

}