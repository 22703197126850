.reclamos{
    margin: 2rem 6rem;
}

.reclamos h1{
    color: #0C0F16;
    font-size: 2.3rem;
    border-bottom: 2px solid #2a2a2a50;
    margin-bottom: 2rem;
}

.reclamos p{
    color: #0C0F16;
    padding: 0.2rem 0;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: justify;
    white-space: pre-wrap;
}

.reclamos p > i{
    padding-right: 0.5rem;
    font-weight: 600;
    color: #0A37B2;
}

.reclamos ul{
    padding: 0.5rem 0 1rem 3rem;
    font-weight: 400;
}

@media screen and (max-width: 850px){
    .reclamos{
        padding-top: 4rem;
        margin: 4rem 2rem;
        font-size: 1rem;
    }

    .reclamos h1{
        font-size: 1.7rem;
    }

    .reclamos p{
        font-size: 1rem;
    }
}