.address{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.address-text{
    width: 100%;
    text-align: start;
    padding: 1rem 1rem 0rem 1rem;
    color: #F7F7F7;
}

.address-text li{
    list-style: none;
    display: flex;
    justify-content: flex-start;
    padding: .5rem 0;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 300;
}

.address-text li p{
    margin: 0;
    text-align: start;
}

.address-text li i{
    padding-right: 0.8rem;
    font-size: 1rem;
}

.address-map{
    width: 100%;
    height: 100%;
    padding: 1rem 0rem;
    margin-top: auto;
}

.address-map iframe{
    width: 100%;
    height: 100%;
    min-height: 300px;
    border-radius: 8px;
    /* box-shadow: 0 5px 10px rgb(0, 0, 0,0.5); */
}

@media screen and (max-width: 700px){

    .address-text{
        width: 100%;
        text-align: start;
    }
    
    .address-text li{
        font-size: 0.8rem;
    }

}