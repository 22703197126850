body{
    overflow-x: hidden;
}

.popup-container{
    background-color: #ffff;
    border-radius: 12px;
    text-align: start;
    position: fixed;
    top: 120px;
    right: 35px;
    padding: 15px 30px 15px 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 1);
    border-left: 6px solid #5C86FA;
    overflow: hidden;
    transform: translateX(calc(100% + 50px));
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.35);

}

.popup-container.active{
    transform: translateX(0%);
} 

.popup-content{
    display: flex;
    align-items: center;
}

.popup-content .fa-check{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    font-size: 20px;
    border-radius: 50%;
    background-color: #01C125;
    color: #ffff;
}

.popup-content .fa-times{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    font-size: 20px;
    border-radius: 50%;
    background-color: #E80A24;
    color: #ffff;
}

.popup-content .message{
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}

.message .text1{
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
}

.message .text2{
    font-size: 1rem;
    font-weight: 400;
    color: #666666;
}

.popup-container .close{
    position: absolute;
    right: 15px;
    top: 10px;
    padding: 5px;
    cursor: pointer;
    opacity: 0.8;
}

.popup-container .close:hover{
    opacity: 1;
}

.popup-container .progress{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: #ffff;
}

.popup-container .progress:before{
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: #5C86FA;
}

.progress.active:before{
    animation: progress 4s linear forwards;
}

@keyframes progress{
    100%{
        right: 100%;
    }
}

@media screen and (max-width: 1000px){
    .popup-container{
        padding: 10px 25px 10px 15px;
        border-left: 4px solid #5C86FA;
        right: 25px;
    
    }

    .popup-content .fa-check, .popup-content .fa-times{
        height: 25px;
        width: 25px;
        font-size: 18px;
    }

    .popup-content .message{
        margin: 0 10px;
    }

    .message .text1{
        font-size: 1rem;
    }
    
    .message .text2{
        font-size: .9rem;
    }

}