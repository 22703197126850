.partner-container{
        padding: 2rem 1rem;
}

.partner-container h1{
    text-align: center;
    font-size: 2.5rem;
    color: #0C0F16;
}

.partner-container p{
    color: #0C0F16;
    text-align: center;
    font-size: 1.2rem;
}

.pt-container{
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    
}

.pt-content{
    padding: 1rem .5rem;
    margin: 0;
    width: 280px;
    display: flex;
    justify-content: stretch;
}

.pt-content .item-image{
    width: 100%;
    height: 100%;
    }
    
    .pt-content .item-image img{
        width: 100%;
        height: 60px;
        object-fit:contain;
    }

@media screen and (max-width: 750px){

    .pt-container{
        padding: 0;
    }

    .partner-container h1{
        font-size: 1.6rem;
    }
    
    .partner-container p{
        font-size: 0.9rem;
    }

    .pt-content{
        padding: 1rem .5rem;
        margin: 0;
        width: 160px;
    }
}