.Blog{
    margin: 1rem 6rem;
    font-size: 1.2rem;
    color: #0C0F16;
    text-align: center;
}

.Blog h1{
    font-size: 2.3rem;
}

.Blog .blog-presentacion{
    padding-top: 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: justify;
    white-space: pre-wrap;
}

.Blog .blog-valores{
    padding-top: 3rem;
}

.Blog .blog-valores h2{
    padding-bottom: 1rem;
    text-align: start;
}

.Blog .blog-valores p{
    padding: 0.2rem 0;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: start;
    white-space: pre-wrap;
}

.Blog .blog-valores p > i{
    padding-right: 0.5rem;
    font-weight: 600;
    color: #0A37B2;
}

.blog-img{
    position: relative;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.blog-img img{
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.2);
}

.first-des{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.first-des-reverse{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.des-text{
    width: 45%;
}

.des-text h2{
    padding-bottom: 1rem;
    text-align: start;
}

.des-text p{
    padding-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: justify;
    white-space: pre-wrap;
}

.des-img{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.des-img img{
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.2);
}

.des-img img:nth-child(2){
    position: absolute;
    top: -10%;
    right: 0;
}

@media screen and (max-width: 850px){
    .Blog{
        margin: 1rem 2rem;
        font-size: 1rem;
    }

    .Blog h1{
        font-size: 1.7rem;
    }

    .des-text h2, .Blog .blog-valores h2{
        font-size: 1.4rem;
    }

    .des-text p{
        font-size: 1rem;
    }

    .Blog .blog-presentacion, .Blog .blog-valores p{
        font-size: 1rem;
    }

    .Blog .blog-valores p{
        padding: 0.5rem 0;
    }

    .blog-img{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        margin-top: 2.5rem;
    }

    .blog-img img{
        width: 100%;
        height: 350px;
        margin-bottom: 1rem;
    }

    .first-des{
        display: flex;
        flex-direction: column-reverse;
    }

    .first-des-reverse{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .des-text{
        width: 100%;
    }

    .des-img{
        width: 100%;
        margin: 1.8rem 0;
    }

    
}

@media screen and (max-width: 570px){

    .des-img img{
        height: 250px;
    }

    .blog-img img{
        height: 250px;
    }
}