body{
    overflow-x: hidden;
}
.hero{
    width: 100%;
    height: 100vh;
    position: relative;
}

.hero-mid{
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-mid img, .hero img{
    width: 100%;
    height: 100%;
    object-fit:cover;
}

.Hero-text{
    position: absolute;
    top: 92%;
    left: 0%;
    transform: translate(-0%, -50%);
    width: 40%;
    background-color: #00217A;
    border-radius: 0px 90px 40px 0px;
}

.Hero-text h1{
    text-align: end;
    padding-right: 3rem;
    font-size: 2.5rem;
    font-weight: 600;
    color: #f7f7f7;

}

.hide{
    display: none !important;
}

.Hero-text-mid{
    position: absolute;
    top: 60%;
    left: 0%;
    transform: translate(-0%, -50%);
    width: 40%;
    background-color: #00217A;
    border-radius: 0px 90px 40px 0px;
}

.Hero-text-mid h1{
    text-align: end;
    padding-right: 3rem;
    font-size: 2.5rem;
    font-weight: 600;
    color:#f7f7f7 ;

}

@media screen and (max-width: 950px){

    .Hero-text,.Hero-text-mid{
        width: 50%;
    }
    
    .Hero-text h1{
        font-size: 2.3rem;
    }
    
    .Hero-text-mid h1{
        font-size: 2.3rem;
    }

}

@media screen and (max-width: 700px){

    .Hero-text, .Hero-text-mid{
        width: 60%;
    }
    
    .Hero-text h1{
        font-size: 2.2rem;
    
    }

    .Hero-text-mid h1{
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 570px){

    .Hero-text, .Hero-text-mid{
        width: 70%;
    }
    
    .Hero-text h1{
        padding-right: 2.5rem;
    
    }
    .Hero-text-mid h1{
        padding-right: 2.5rem;
    }
 
}