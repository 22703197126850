.Contact-container{
    display: flex;
    flex-direction: row;
    align-items:stretch;
    justify-content: center;
  }
  
  .Contact-container .col1{
    width: 60%;
  }
  
  .Contact-container .col2{
    width: 40%;
  }
  
  .container-form{
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
}

.register-form{
    padding: 2rem 0;
}

.container-form form{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
}

.register-form form{
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: auto;
}

.container-form h1, .register-form h1{
    text-align: center;
    font-size: 2.3rem;
    line-height: 2.5rem;
    text-transform: uppercase;
    color: #0C0F16;
}

.container-form span{
    text-align: center;
    width: 100%;
    font-size: .9rem;
    font-weight: 600;
    color: #5C86FA;
}

.input-group{
    display: flex;
    flex-direction: row;
}
.input-group > select{
    width: 30% !important;
    margin-right: 1rem;
}

.input-group > input{
    width: 70% !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
input[type=number] { 
    -moz-appearance:textfield;
    appearance:textfield; }

.input-group > i{
    position: absolute;
    right: 15px;
    top: 40%;
    transform: translateY(-50%);
    font-size: 1.4rem;
    color: #b4b4b4;
}

.container-form input, .container-form select, .register-form input, .register-form select{
    color: #303749;
    width: 100%;
    height: 2.2rem;
    padding: 0 1rem;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    border: 2px solid rgba(0, 0, 0, 0.35);
}

input::placeholder, textarea::placeholder{
    color: #9DA3B5;
}

.container-form textarea{
    padding: 1rem;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
    border: 2px solid rgba(0, 0, 0, 0.35);
}

.container-form button, .register-form button{
    background-color: #E80A24;
    color: #fff;
    padding: 0.4rem 1.2rem;
    margin: 1rem 0;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: 2px solid #E80A24;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.container-form button:hover, .register-form button:hover{
    background-color: #CB061D;
    border-color: #CB061D;
}

.container-form label{
    font-size: .9rem;
    padding: 0 0 .3rem .5rem;
    margin: 0;
    text-align: start;
    color: #585F73;
}

.container-form .checklbl{
    display: flex;
    padding: 1rem 1.5rem;
    text-align: justify;
}

input[type='checkbox']{
    width:unset;
    margin: 0 15px 0 0;
    
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #5D616D;
    color: #fff;
    text-align: start;
    padding: 0.25rem;
    position: relative;
    bottom: 0.5rem;
    opacity: 1;
    height: auto;
    max-height: 200px;
    pointer-events:all;
    transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out, padding 500ms ease-in-out;
}

.instructions > i {
    margin-right: 0.4rem;
}

.offscreen {
    opacity: 0;
    max-height: 0px;
    padding: 0 0.25rem;
    pointer-events: none;
}

.question{
    padding: 1rem 0 0 0;
    text-align: center;
}

.question a{
    padding-left: 0.2rem;
    text-decoration: none;
    color: #124FF8;
}

/* ------------------    Profile Styles   ------------------------ */ 

.profile-container{
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-container h2{
    color: #0C0F16;
    padding-bottom: 1rem;
    text-align: center;
}

.profile-container .content{
    padding: 1rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-container .content .info-group{
    width: 100%;
    margin: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.profile-container .content .info-group label{
    width: 100%;
    text-align: center;
    font-weight: 500;
    background-color: #00268F;
    color: #fff;
    border-radius: 0.3rem 0.3rem 0 0;
    border: 2px solid rgba(0, 0, 0, 0.082);
}
.profile-container .content .info-group span{
    color: 0C0F16;
    width: 100%;
    text-align: center;
    width: 100%;
    height: 2.2rem;
    padding: 0 1rem;
    border-radius: 0 0 0.3rem 0.3rem;
    border: 2px solid rgba(0, 0, 0, 0.35);
}

@media screen and (max-width: 900px){

    .Contact-container{
        flex-direction: column;
      }
      
      .Contact-container .col1{
        width: 100%;
      }
      
      .Contact-container .col2{
        width: 100%;
        padding-top: 2rem;
      }

    .container-form form{
        padding-top: 2rem;
    }

    .container-form h1{
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
}

@media screen and (max-width: 700px){
    .question{
        font-size: .9rem;
    }

    .register-form form{
        padding-top: 2rem;
        width: 85%;
    }

    .container-form form{
        width: 80%;
    }

    .container-form h1{
        font-size: 1.3rem;
        padding: 0 0.5rem;
    }

    .profile-container .content{
        padding: 1rem;
        width: 90%;
    }

}