.ddlist-container h1{
    text-align: center;
    padding-top: 2rem;
    margin: 0 2rem;
    font-size: 2.5rem;
    color: #0C0F16;
}

.ddlist-container p{
    text-align: center;
    font-size: 1.2rem;
    padding: 0 1rem;
}

.ddl-container{
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
}

.ddl-content{
    padding: .5rem;
    margin: 0.5rem 0;
    width: 75%;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    box-shadow: -1px -1px 2px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.ddl-content .item-image{
    width: 100%;
    height: 50px;
    padding: 3px 0.5rem;
    justify-content: center;
    align-items: center;
    }
    
    .ddl-content .item-image img{
        width: 100%;
        height: 100%;
        object-fit:contain;
    }

    .ddl-content .item-text{
        background-color: #fff;
        width: 100%;
        height: auto;
        max-height: 0px;
        border-radius: 10px; 
        opacity: 0;
        pointer-events: none;
        transition: all 350ms ease-in-out;
    }

    .ddl-content .act{
        max-height: 500px;
        opacity: 1;
        padding-bottom: 1rem;
        pointer-events: auto;
    }

    .ddl-content .item-text h3{
        text-align: start;
        padding: 0.5rem 0;
        margin-left: 2rem;
        margin-top: .5rem;
        font-size: 1rem;
        font-weight: 500;
    }

    .ddl-content .item-text h3 > i{
        margin-right: 0.5rem;
        font-weight: 300;
    }

    .ddl-content .item-text p{
        text-align: justify;
        padding: 0.1rem 0;
        margin-left: 3rem;
        font-size: .9rem;
        font-weight: 400;
    }

    .ddl-content .item-text p > i{
        margin-right: 0.5rem;
        font-weight: 600;
        color: #0A37B2;
    }

@media screen and (max-width: 750px){

    .ddl-content{
        padding: 1rem;
        width: 100%;
    }

    .ddlist-container h1{
        font-size: 1.7rem;
    }
    
    .ddlist-container p{
        font-size: 1rem;
    }

    .ddl-content .item-text h3{
        margin-left: 1rem;
    }

    .ddl-content .item-text p{
        margin-left: 2rem;
    }

    .ddl-content .item-image{
        height: 30px;
        padding: 0px 0.5rem;
        }
}