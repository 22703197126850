.createhiring-container, .edithiring-container{
    padding: 2rem 0;
}

.createhiring-container h2, .edithiring-container .edit-title{
    color: #0C0F16;
    text-transform: uppercase;
    text-align: center;
}

.createhiring-container form, .edithiring-container form{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
}

.createhiring-container input, .edithiring-container input{
    color: #303749;
    width: 100%;
    height: 2.2rem !important;
    padding: 0 1rem;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    border: 2px solid rgba(0, 0, 0, 0.35);
    font-size: .9rem;
}

.createhiring-container input[type=file], .edithiring-container input[type=file]{
    display: none;
}

.createhiring-container label, .edithiring-container label{
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createhiring-container label span, .edithiring-container label span{
    text-decoration: none;
    width: 100%;
    height: 2.2rem !important;
    padding: 0.5rem 1rem;
    border: 2px solid rgba(0, 0, 0, 0.35);
    font-size: .9rem;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
}

.createhiring-container label .file-title, .edithiring-container label .file-title{
    width: 60px;
    font-weight: 500;
    background: #eaecec;
    color: #303749;
    border-radius: 0.3rem 0 0 0.3rem;
}

.createhiring-container label .file-name, .edithiring-container label .file-name{
    background: #ffffff;
    color: #303749;
    border-radius: 0 0.3rem 0.3rem 0;
}

.createhiring-container label .file-title:hover, .edithiring-container label .file-title:hover{
    color: #0A37B2;
  }

.createhiring-container textarea, .edithiring-container textarea{
    color: #303749;
    resize: vertical;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    border: 2px solid rgba(0, 0, 0, 0.35);
    font-size: .9rem;
}

.createhiring-container .ql-container {
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    border: 2px solid rgba(0, 0, 0, 0.35);
    color: #303749;
    background: #fefcfc;
    font-size: .9rem;
    overflow-y: auto;
  }

.createhiring-container .ql-snow.ql-toolbar {
    display: block;
    text-align: center;
    background: #eaecec;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    border: 2px solid rgba(0, 0, 0, 0.35);
  }

.createhiring-container button, .edithiring-container button{
    background-color: #E80A24;
    color: #fff;
    padding: 0.4rem 1.2rem;
    margin: 1rem 0;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: 2px solid #e80a2486;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.createhiring-container button:hover, .edithiring-container button:hover{
    background-color: #CB061D;
    border-color: #CB061D;
}

/* ----------------------------------- Hiringformat Styles -------------------------------------- */

.hiringformat-container{
    margin: 3rem;
    height: 40vh;
    box-shadow: 1px 35px 50px -58px rgba(0,0,0,0.50);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hiringformat-container .item-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 100%;
    padding: 1rem;

}

.hiringformat-container .item-img img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    padding: 0;
    border-radius: 0.5rem;
}

.hiringformat-container .item-text{
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: auto;
    padding: 0.5rem 2rem;
    text-align: start;
}
.hiringformat-container .top-content{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.hiringformat-container .top-content .date{
    min-width: 220px;
    text-align: right;
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0.2rem;
}

.hiringformat-container .top-content .date > i{
    padding-right: 0.5rem;
    text-align: end;
    font-size: 1rem;
    font-weight: 500;
    color: #0A37B2;
}

.hiringformat-container .top-content .title{
    width: 100%;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
    line-height: 2rem;
    color: #2a2a2a;
    transition: all 0.2s ease-in-out;
}

.hiringformat-container .resumen{
    width: 100%;
    margin: 0.5rem 0;
    text-align: justify;
    font-size: 1rem;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.hiringformat-container .bottom-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}



.hiringformat-container .bottom-content .btn{
    text-align: center;
    margin-top: auto;
    margin-bottom: .5rem;
    text-decoration: none;
    background-color: #0A37B2;
    color: #fff;
    padding: 0.4rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    font-weight: 500;
    border: 2px solid #0A37B2;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.hiringformat-container .bottom-content .btn:hover{
    background-color: #01237E;
    border-color: #01237E;
}

.hiringformat-container .bottom-content .h-btn{
    text-align: center;
    margin-top: 1rem;
    text-decoration: none;
    color: #01237E;
    padding: 0.2rem 0;
    white-space: nowrap;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
}

.hiringformat-container .bottom-content .h-btn span{
    padding-left: 0.5rem;
    transition: all 0.2s ease-in-out;
}

.hiringformat-container .bottom-content .h-btn:hover span{
    padding-left: 1.5rem;
}

/* -------------------------------  SingleNews Styles ------------------------------------ */

.singlehiring-container{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding: 1rem 2rem;
}

.singlehiring-container h1{
    margin-top: 1rem;
    text-align: center;
    font-size: 2.3rem;
    line-height: 2.5rem;
}

.singlehiring-container .middle-section{
    padding: 2rem 5rem 0.5rem 5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.singlehiring-container .middle-section span{
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
}

.singlehiring-container .middle-section span > i{
    padding-right: 0.5rem;
    text-align: end;
    font-size: 1rem;
    font-weight: 500;
    color: #0A37B2;
}

.singlehiring-container .middle-section .btn-panel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.singlehiring-container .middle-section .btn-panel .btn{
    margin: 0 0.5rem;
    text-decoration: none;
    background-color: #0A37B2;
    color: #fff;
    padding: 0.3rem 1.2rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 2px solid #0A37B2;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.singlehiring-container .middle-section .btn-panel .btn:hover{
    background-color: #01237E;
    border-color: #01237E;
}

.singlehiring-container .middle-section .btn-panel .del{
    background-color: #E80A24 !important;
    border-color: #E80A24 !important;
}

.singlehiring-container .middle-section .btn-panel .del:hover{
    background-color: #CB061D !important;
    border-color: #CB061D !important;
}

.singlehiring-container .middle-section .btn-panel .btn > i{
    padding-right: 0.5rem;
    font-size: 1rem;
}

.singlehiring-container .middle-section .social-media i{
    width: 40px;
    height: 40px;
    background-color: #0A37B2;
    border-radius: 13px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.2rem;
    margin-left: 1rem;
    transition: all 0.2s ease-in-out;
}

.singlehiring-container .middle-section .social-media i:hover{
    color: #FFF01A;
}

.singlehiring-container .item-image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
    padding: 0.5rem 4rem;

}

.singlehiring-container .item-image img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 0.5rem;
}

.singlehiring-container .item-content{
    width: 100%;
    padding: 0.5rem 5rem;
}

.singlehiring-container .item-content > *{
    width: 100%;
}

.singlehiring-container blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
}

.singlehiring-container .bottom-content{
    width: 100%;
    padding: 1rem 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}



.singlehiring-container .bottom-content .btn{
    text-align: center;
    margin-top: auto;
    margin-bottom: .5rem;
    text-decoration: none;
    background-color: #0A37B2;
    color: #fff;
    padding: 0.4rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    font-weight: 500;
    border: 2px solid #0A37B2;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.singlehiring-container .bottom-content .btn:hover{
    background-color: #01237E;
    border-color: #01237E;
}

.singlehiring-container .bottom-content .h-btn{
    text-align: center;
    margin-top: 1rem;
    text-decoration: none;
    color: #01237E;
    padding: 0.2rem 0;
    white-space: nowrap;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
}

.singlehiring-container .bottom-content .h-btn span{
    padding-right: 0.5rem;
    transition: all 0.2s ease-in-out;
}