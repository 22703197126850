.notfound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;
}

.notfound h1{
    font-size: 6rem;
    color: #124FF8;
  letter-spacing:2vmin;
  margin:2vmin auto;
  text-shadow:1px 1px #00268F,
  2px 2px #00268F,
  3px 3px #00268F,
  4px 4px #00268F,
  5px 5px #00268F,
  6px 6px #00268F,
  7px 7px #00268F,
  8px 8px #00268F,
  9px 9px #00268F,
  10px 10px #00268F,
  10px 11px #9DA3B5,
  8px 12px #9DA3B5,
  6px 13px #9DA3B5,
  4px 14px #9DA3B5,
  2px 15px #9DA3B5,
  0px 16px #9DA3B5,
  -2px 17px #9DA3B5,
  -4px 18px #9DA3B5,
  -6px 19px #9DA3B5,
  -8px 20px #9DA3B5,
  -10px 21px #9DA3B5
  ;
}

.notfound h2{
  background: #124FF8;
  color: #fff;
  padding: 0.2rem 1rem;
  font-size: 2rem;
  font-weight: 400;
  transform: skew(-20deg);
}

.notfound p{
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 0;
}

.notfound button{
  background-color: #E80A24;
  color: #fff;
  padding: 0.1rem 1.5rem;
  margin-top: 2rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid #E80A24;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.notfound button:hover{
  background-color: #FD354C;
  color: #fff;
  border-color: #FD354C;
}

@media screen and (max-width: 550px){

  .notfound h2{

    padding: 0.1rem .6rem;
    font-size: 1.3rem;

  }

  .notfound p{
    font-size: .7rem;
    font-weight: 400;
    margin-bottom: 4rem;
  }
}