.credit-presentacion{
    margin: 1rem 4rem;
    padding-top: 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: justify;
    white-space: pre-wrap;
}

.credit-container{
    margin: 3rem;
    height: 60vh;
    box-shadow: 1px 35px 50px -58px rgba(0,0,0,0.50);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.inverse{
    display: flex;
    flex-direction: row-reverse;
}

.credit-container .item-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 100%;
    padding: 1rem;

}

.credit-container .item-img img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    padding: 0;
    border-radius: 0.5rem;
}

.credit-container .item-text{
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: auto;
    padding: 2rem 3rem 2rem 1rem;
    text-align: start;
}

.credit-container .title{
    width: 100%;
    font-size: 1.7rem;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 0.4px;
    line-height: 2rem;
    color: #00217A;
    transition: all 0.2s ease-in-out;
}

.credit-container .title:hover{
    color: #00217ad4;
}

.credit-container .resumen{
    width: 100%;
    margin: 0.5rem 0;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: justify;
    white-space: pre-wrap;
}

.first .resumen{
    -webkit-line-clamp: 5 !important;
}

@media screen and (max-width: 750px){

    .credit-presentacion{
        margin: 1rem 2rem;
    }

    .credit-container{
        margin: 2rem 1rem;
        height: 80vh;
        box-shadow: 1px 35px 50px -58px rgba(0,0,0,0.50);
        display: flex;
        flex-direction:column-reverse;
        align-items: center;
        justify-content: center ;
    }

    .credit-container .item-img{
        width: 100%;
        height: 60%;
        padding: 0.5rem;
        align-self: auto;
    
    }

    .credit-container .item-text{
        width: 100%;
        height: 40%;
        padding: 1rem 0.5rem 0.5rem 0.5rem ;
        align-self: auto;
    }

    .credit-container .title{
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.2px;

    }
}