.NavbarItems{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 95%;
    height: 70px;
    border-radius: 13px;
    box-shadow: 0px 2px 20px 0px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 999;
}

.NavbarItems .home{
    text-decoration: none;
}

.scroll{
    background-color: #5C86FA !important;
    right: 15px !important;
    bottom: 20px !important;
}

.NavbarLogo{
    width: 270px;
    height: 54px;
    cursor: pointer;
}

.NavbarLogo img{
    width: 100%;
    height: 100%;
    object-fit:contain;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(6,auto);
    grid-gap: 5px;
    list-style: none;
    align-items: center;
    height: 100%;
}

.nav-menu li{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}


.nav-links{
    text-decoration: none;
    position: relative;
    color: #0C0F16;
    font-size: 1rem;
    padding: 0.4rem 1rem;
    font-weight: 600;
    white-space: nowrap;

}

.nav-links::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #0A37B2;
    transition: width 0.25s ease-out;
  }

  .nav-menu li:hover .nav-links::before {
    width: 85%;
  }

.nav-links i{
    padding-right: 8px;
}

.menu-icon{
    display: none;
}

.invisible{
    display: none;
}

.submenu{
    position: absolute;
    top: 70px;
    left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto; 
    max-height: 0px;
    background-color: #fff;
    border-radius: 0 0 6px 6px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 400ms ease-in-out, max-height 400ms ease-in-out;
}

.nav-menu li:hover .submenu{
    max-height: 500px;
    opacity: 1;
    pointer-events: auto;
}

.nav-menu li{
    flex-direction: column;
}

.submenu .nav-sublinks{
    display: block;
    text-align: justify;
    width: 100%;
    padding: 1rem;
    text-decoration: none;
    color: #0C0F16;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;


}

.submenu .nav-sublinks:hover{
    color: #0A37B2;
    transition: all 0.2s ease-in-out;
}

.admin-section .profile-btn{
    color: #fff;
    font-size: 1rem;
    background-color: #0A37B2;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 13px;
    transition: all 400ms ease-in-out;
}

.admin-section .profile-btn:hover{
    background-color: #fff;
    color: #0A37B2;
    outline: solid 2px #0A37B2;

}

.admin-section .submenu{
    right: 0;
    left: inherit;
}

.admin-section li i{
    padding-right: 8px;
}

@media screen and (max-width: 1250px){

    .NavbarItems{
        z-index: 99;
    }

    .nav-menu{
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: auto;    
        position: absolute;
        top: 0;
        left: -105%;
        align-items: stretch;
        opacity: 0;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;
        z-index: -1;
    }

    .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }

    .nav-links{
        display: block;
        text-align: justify;
        width: 100%;
        font-size: 1.1rem;
        padding: 1rem 0;
        padding-left: 3rem;

    }

    .submenu{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 500px;
        opacity: 1;
        pointer-events: auto;
        justify-content: flex-start;
    }

    .submenu .nav-sublinks{
        padding-left: 3rem;
    }

    .menu-icon{
        display: block;
        cursor: pointer;
    }

    .menu-icon i{
        font-size: 1.1rem;
        color: #0C0F16;

    }

    .admin-section .profile-btn{
        display: none;
    }
}