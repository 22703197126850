.footer{
    padding: 3rem 4rem;
    background-color: #00217A;
    color: #fff;
}

.top{
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.FooterLogo{
    height: 70px;
    cursor: pointer;
}

.FooterLogo img{
    width: 100%;
    height: 100%;
    object-fit:contain;
}

.top i{
    width: 43px;
    height: 43px;
    background-color: #00217A;
    border-radius: 50%;
    box-shadow: -1px -4px 2px 2px rgba(0, 0, 0, 0.2);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.5rem;
    margin-left: 1rem;
    transition: all 0.2s ease-in-out;
}

.top i:hover{
    color: #FFF01A;
    box-shadow: -1px -1px 1px 1px rgba(0, 0, 0, 0.1);
}

.bottom{
    padding-top: 1rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bottom div{
    display: flex;
    flex-direction: column;
    padding: 0.5rem;

}

.bottom h4{
    color: #FFF01A;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 1rem 0 0.5rem 0;
}

.bottom a{
    text-decoration: none;
    color: #fafafa;
    padding-bottom: 0.6rem;
    font-size: 0.9rem;
    font-weight: 300;
}

.bottom a i{
    padding-right: 0.7rem;
    font-size: 1rem;

}

.bottom a:hover{
    color: #FFF01A;
}

@media screen and (max-width: 1170px){
    .footer{
        padding: 3rem 2rem;
    }

    .footer .top i{
        margin: 0.5rem;
    }

    .bottom div{
        width: 50%;
    }
}

@media screen and (max-width: 750px){
    .footer{
        padding: 3rem 2rem;
    }

    .top{
        flex-direction: column;
    }

    .bottom h4{
        font-size: 1.1rem;

    }

    .bottom a{
        font-size: 0.8rem;
    }

    .bottom div{
        width: 100%;
    }

}